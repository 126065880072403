<template>
  <v-app>
    <v-container fluid class="fill-height" id="login-bg">
      <v-row align="center" justify="center">
        <v-col cols="11" sm="8" md="6" lg="5">

          <v-card v-if="!cuentaActivada" class="elevation-12">
            <v-card-title v-if="loading">
              <h3>Activando cuenta...</h3>
            </v-card-title>
            <v-card-title v-if="loading">
              <v-progress-linear indeterminate height="20"></v-progress-linear>
            </v-card-title>
            <v-divider class="mx-3"></v-divider>
            <v-card-title v-if="error">
              <h3>Ooops... 😥</h3>
            </v-card-title>
            <v-card-text v-if="error">
              Esto es incómodo, ha sucedido un error al activar la cuenta :(
            </v-card-text>
          </v-card>

        <v-card v-else>
          <v-card-title>
            Cuenta activada correctamente 🥳
          </v-card-title>
          <v-card-text>
            Ahora podrás acceder mediante correo y contraseña.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" small @click="goToLogin()">Continuar</v-btn>
          </v-card-actions>
        </v-card>

        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>

import { activateAccount } from "./login.service";

export default {
  name: "activarCuenta",
  data() {
    return {
      loading: true,
      error: false,
      tokenActivacion: null,
      cuentaActivada: false
    };
  },
  mounted() {
    this.tokenActivacion = this.$route.params.tokenActivacion;
    this.activarCuenta();
  },
  methods: {
    async activarCuenta() {
      try {
        this.loading = true;
        const serverResponse = await activateAccount(this.tokenActivacion);
        this.$validateResponse(serverResponse);
        this.loading = false;
        if(!serverResponse.ok)
          this.error = true;
        else
            this.cuentaActivada = true;
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },
    goToLogin(){
      this.$router.push('/login');
    }
  },
};
</script>

<style>
.flat-link {
  text-decoration: none;
}
#login-bg {
  height: 100vh;
  width: 100vw;
  background-color: #0d47a1;
  background-image: linear-gradient(10deg, #81d4fa 50%, transparent 50%),
    linear-gradient(-60deg, #5e35b1 30%, transparent 30%);
}
</style>
